// import nuxtStorage from "nuxt-storage";

export default defineNuxtRouteMiddleware((to, from) => {

  const nuxtApp = useNuxtApp();
  const localePath = useLocalePath();
  // setTimeout(() => {
  // const token = nuxtApp.$nuxtStorage?.localStorage?.getData("token");
  const authToken = useCookie('isAuthenticated').value;

  if (!authToken) {
    // User is not authenticated, redirect to login or handle accordingly
    return nuxtApp.$router.push({
      path: localePath("/account/login"),
      query: { redirectTo: to.path },
    }); // Replace with your login route
  }
  // User is authenticated, allow navigation
  return true;
  // }, 1000);
});


